import React, { useContext, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/shop.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faComments,
  faGear,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

function Main() {
  const { addToCart } = useContext(CartContext);
  const [buttonClickedStatus, setButtonClickedStatus] = useState({});
  const navigate = useNavigate();

  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const linkToCart = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleAddToCart = (productId) => {
    addToCart(productId, 1);
    setButtonClickedStatus((prevState) => ({
      ...prevState,
      [productId]: true,
    }));
  };
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img alt="" src="/cloth/101.png" />
        </div>
        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <h1>SAVE WITH BUNDLES!</h1>
              <div className="product-grid">
                {productData.map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <Link
                        to={`/shopDetails/${brand.id}`}
                        onClick={handleNavigate}
                      >
                        <img alt="" src={brand.images[0]} />
                      </Link>
                      <h2>{brand.name}</h2>
                      <div className="pp-pre">
                        <h4 className="red-price">
                          <strong>{brand.price}</strong>
                        </h4>
                        <h4 className="pre-underline">{brand.preprice}</h4>
                      </div>
                      <button
                        className="to-cart-btn"
                        onClick={() => handleAddToCart(brand.id)}
                      >
                        Quick Add
                        {buttonClickedStatus[brand.id] && (
                          <FontAwesomeIcon icon={faCheck} />
                        )}
                      </button>
                      <Link
                        className="view-cart"
                        to="/cartpage"
                        onClick={linkToCart}
                      >
                        <a href="/cartpage">View basket</a>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="jsVideo">
              <div className="one-third-left">
                <img alt="" src="/cloth/102.jpg" />
              </div>
              <div className="one-third-mid">
                <h4>Real Cleaning</h4>
                <p>
                  At E-Cloth, we believe in the power of small changes to make a
                  big impact. By swapping out traditional "cleaning" products
                  for our eco-friendly microfibre cloths, you can reduce your
                  environmental footprint and make a positive impact on the
                  planet. Our mission is to make sustainable cleaning accessible
                  to everyone, without sacrificing performance or affordability.
                  With E-Cloth, you can clean your home with confidence, knowing
                  that you're using<strong> water-activated cleaning™</strong>{" "}
                  that's gentle on the environment and your wallet. Join us in
                  our mission to create a greener, more sustainable world, one
                  clean home at a time.
                </p>
              </div>
              <div className="one-third-right">
                <img alt="" src="/cloth/103.jpg" />
              </div>
            </div>
            <div className="is-relative">
              <div className="is-left">
                <img alt="" src="/cloth/ac1.jpg" />
              </div>
              <div className="is-mid">
                <img alt="" src="/cloth/ac2.jpg" />
              </div>
              <div className="is-right">
                <img alt="" src="/cloth/ac3.jpg" />
              </div>
            </div>
            <div className="is-regular">
              <div className="regular-left">
                <h3>Earn On Every Purchase</h3>
                <h4>Join our Rewards Program</h4>
                <div className="pp-regular">
                  <p>
                    It’s easier than ever to earn rewards on every E-Cloth
                    purchase and referral.
                  </p>
                  <p>
                    Reward Members earn 10 points per dollar and gain access to
                    exclusive offers.
                  </p>
                  <p>Learn more and sign up now for 500 points - $5 Reward</p>
                </div>
              </div>
              <div className="is-right">
                <img alt="" src="/cloth/clean1.jpg" />
              </div>
            </div>
            <div className="testimonial-block">
              <img alt="" src="/cloth/smile.webp" />
            </div>
            <div class="testimonial__description">
              <p>"It's easy, eco-friendly and</p>
              <p>actually works."</p>
            </div>
            <div className="crop">
              <img alt="" src="/cloth/today.avif" />
            </div>
            <div className="crop-svg">
              <div className="cropSvg">
                <FontAwesomeIcon icon={faComments} />
                <p class="icon-bar__text ">chat</p>
              </div>
              <div className="cropSvg">
                <FontAwesomeIcon icon={faEnvelope} />
                <p class="icon-bar__text ">email</p>
              </div>
              <div className="cropSvg">
                <FontAwesomeIcon icon={faPhone} />
                <p class="icon-bar__text ">call</p>
              </div>
              <div className="cropSvg">
                <FontAwesomeIcon icon={faGear} />
                <p class="icon-bar__text ">FAQ</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
