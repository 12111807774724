const productData = [
  {
    id: 1,
    name: "Kitchen Roll Replacement Bundle",
    description:
      "You have been Challenge! How long can you go without using kitchen rolls and disposable wipes? This is the ultimate solution to tackle this challenge. With this comprehensive set comprising of 4 General Purpose Cloths, 4 Glass & Polishing Cloths, 4 Wash & Wipe Cloths, a Cleaning Pad, and a Kitchen Whizz, you can take on the battle against waste with confidence. Embrace the power of reusable E-Cloths, meticulously designed with advanced fiber technology to make cleaning without single-use products a breeze. Say goodbye to the struggle of constantly restocking paper towels and wipes, and step into a sustainable cleaning routine that's both effective and eco-friendly. With this bundle, you can conquer the challenge of going green while maintaining a spotless and streak-free home, all while saving money. Join the movement and champion a cleaner, greener future today!",
    Includes:
      "1 General Purpose Cloth 4-Pack 1 Glass & Polishing Cloth 4-Pack 2 Wash & Wipe Cloths 2-Packs 1 Cleaning Pad 1 Kitchen Whizz",
    price: "£30.00",
    preprice: "£43.94",
    images: [
      "/images/10001.jpg",
      "/images/100011.jpg",
      "/images/100012.jpg",
      "/images/100013.jpg",
      "/images/100014.jpg",
    ],
  },
  {
    id: 2,
    name: "The Deep Clean Bundle",
    description:
      "Everything you need for a deep clean, in one convenient set. The Deep Clean Bundle includes products for daily maintenance as well as those targeted for tough messes and hard-to-clean areas. From dusting and mopping to wiping and scrubbing, there isn’t a task (or room) this set can’t handle. Each Deep Clean Bundle offers significant savings and is a quick way to ensure that your home is prepared for all chore types.",
    Includes: "General Purpose Cloths, 4 Pack",
    price: "£30.00",
    preprice: "£43.94",
    images: ["/images/10002.jpg", "/images/100021.jpg"],
  },
  {
    id: 3,
    name: "The Floor Cleaning Bundle",
    description: "",
    Includes: "The Floor Cleaning Bundle - 3 PC",
    price: "£45.50",
    preprice: "",
    images: ["/images/10003.jpg", "/images/100031.jpg"],
  },
  {
    id: 4,
    name: "The Window Cleaning Bundle",
    description:
      "You have been Challenge! How long can you go without using kitchen rolls and disposable wipes? This is the ultimate solution to tackle this challenge. With this comprehensive set comprising of 4 General Purpose Cloths, 4 Glass & Polishing Cloths, 4 Wash & Wipe Cloths, a Cleaning Pad, and a Kitchen Whizz, you can take on the battle against waste with confidence. Embrace the power of reusable E-Cloths, meticulously designed with advanced fiber technology to make cleaning without single-use products a breeze. Say goodbye to the struggle of constantly restocking paper towels and wipes, and step into a sustainable cleaning routine that's both effective and eco-friendly. With this bundle, you can conquer the challenge of going green while maintaining a spotless and streak-free home, all while saving money. Join the movement and champion a cleaner, greener future today!",
    Includes: "The Window Cleaning Bundle - 7 PC",
    price: "£30.00",
    preprice: "",
    images: ["/images/10004.jpg", "/images/100041.jpg", "/images/100042.jpg"],
  },
  {
    id: 5,
    name: "Nontoxic Dishwashing Bundle",
    description:
      "You have been Challenge! How long can you go without using kitchen rolls and disposable wipes? This is the ultimate solution to tackle this challenge. With this comprehensive set comprising of 4 General Purpose Cloths, 4 Glass & Polishing Cloths, 4 Wash & Wipe Cloths, a Cleaning Pad, and a Kitchen Whizz, you can take on the battle against waste with confidence. Embrace the power of reusable E-Cloths, meticulously designed with advanced fiber technology to make cleaning without single-use products a breeze. Say goodbye to the struggle of constantly restocking paper towels and wipes, and step into a sustainable cleaning routine that's both effective and eco-friendly. With this bundle, you can conquer the challenge of going green while maintaining a spotless and streak-free home, all while saving money. Join the movement and champion a cleaner, greener future today!",
    Includes:
      "The Nontoxic Dishwashing Bundle - 6 PC Washing Up PadNon-Scratch Scrubbing Pads, 2 PackWash & Wipe Dish Cloths, 2 Pack Kitchen Whizz",
    price: "£21.50",
    preprice: "",
    images: ["/images/10005.jpg"],
  },
  {
    id: 6,
    name: "Windscreen Demister",
    description:
      "The E-Cloth Windscreen Demister is a driver’s essential. The thick, two-sided pad quickly soaks up distracting windscreen moisture, then doubles as a cleaning pad that keeps glass and mirrors gleaming. Use the extra-absorbent waffle-weave side to drink up moisture. Reverse, dampen, and wipe to spot clean. No mystery sprays needed, thanks to E-Cloth’s powerful microfibres. Good to see you through every journey for 300 washes or 3 years. Try that with a paper towel!",
    Includes: "1 Windscreen Demister",
    price: "£7.00",
    preprice: "",
    images: ["/images/10006.jpg", "/images/100061.jpg", "/images/100062.jpg"],
  },
  {
    id: 7,
    name: "Window Cleaning 2-Pack",
    description:
      "Say good-bye to streaks and cloudy windows. This versatile kit comes with 2 cloths that are made from premium microfibre that will last 300 washes, so you can save money by removing paper towels and cleaning sprays from you shopping list. One is made of microfibre waffle-weave fibers that remove grime and debris while the other has a smooth weave that won’t leave streaks behind. Just dampen with water to clean all your windows inside and out.",
    Includes: "1 Window Cleaning Pack - Select Color",
    price: "£9.50",
    preprice: "",
    images: ["/images/10007.jpg"],
  },
  {
    id: 8,
    name: "Deep Clean Mop",
    description:
      "The E-Cloth Deep Clean Mop is to be used with the E-Cloth Deep Clean Mop Head. The mop head removes grease, dirt and over 99% of bacteria from all types of hard flooring including laminate, wood, tiles, vinyl and stone, using just water. The telescopic handle makes it easy to adjust and move around. To extend the telescopic handle to a comfortable height, hold the bottom of the handle near the base and twist the top handle section. TIP: Mop in an 'S' shape, maintaining the leading edge to prevent dirt being left behind. Wet thoroughly and wring out. Remove, rinse and reattached the head as required. Like you, we think cleaning should be pure and simple. A smarter way to clean, E-Cloth is enough.",
    Includes: "1 Deep Clean Mop with Telescopic Handle",
    price: "£24.00",
    preprice: "",
    images: ["/images/10008.jpg", "/images/100081.jpg", "/images/100082.jpg"],
  },
  {
    id: 9,
    name: "Home Cleaning 8 PC Set",
    description:
      "With one purchase, the E-Cloth Home Cleaning Set equips you to clean and shine every surface in your home, from countertop to hob and windows to tile. This 8-piece set includes 7 cloths that remove over 99% of bacteria – 5 for cleaning and 2 for polishing – plus 1 cloth for dusting. With the muscle of masses of microfibres plus water's molecular might, there’s no need for to add chemical cleaners — or worry about curious kiddos or pets. Just dampen with water and wipe. Gentler to the planet, this E-Cloth set is also easier on your wallet. With made-to-last quality backed by our 3-year or 300-wash promise, you may never put paper towels or chemical cleaners in your shopping trolley again.",
    Includes: "",
    price: "£30.00",
    preprice: "",
    images: ["/images/10009.jpg", "/images/100091.jpg", "/images/100092.jpg"],
  },
  {
    id: 10,
    name: "Cleaning Pad",
    description:
      "A must for kitchens and bathrooms, the extra-absorbent E-Cloth Cleaning Pad laps up spills and messes in a nanosecond. Sectioned for easy folding, the pad traps and removes grime plus over 99% of bacteria — with just water. How? With masses of precision-engineered microfibres. There’s no need to add chemical cleaners that could travel down the drain or linger in the air you breathe. Just dampen, fold, wipe. It couldn't be easier to erase everything from shower scum to greasy microwave splatters and keep appliances, sinks, showers, tile, and more spotless. To refresh, just drop in boiling water or toss in the laundry. Our cleaning pad is made to last — and guaranteed for 1 year or 100 washes. And we think that stacks up nicely against the mountain of paper towels and containers of cleaning fluids you might use otherwise.",
    Includes: "Cleaning Pad - select quantity above",
    price: "£4.90",
    preprice: "",
    images: ["/images/10010.jpg", "/images/100101.jpg", "/images/100102.jpg"],
  },
];

export default productData;
